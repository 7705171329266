import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild, Output, EventEmitter, signal, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  faClock,
  faFileLines,
  faPenToSquare,
} from '@fortawesome/free-regular-svg-icons';
import { faGear, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
// import { ICustomInputOption } from 'src/app/shared/components/custom-input/custom-input.component';
import { ItemTypes, ProductStructureType } from 'src/app/shared/enums/mro/mro-serial-card';
import { HeaderInfo } from 'src/app/shared/models/mro/header-info';
import { SerialCard } from 'src/app/shared/models/mro/serial-card';
import { SearchNumberVal, SerialCardItems } from 'src/app/shared/models/mro/serial-card-items';
import { CommonUtilityLibraryService } from '../../library/common-utility-library.service';
import { SearchFilterType, SerialTabEnum } from '../../enums/common';
import { MroApiSerialService } from '../../services/mro/mro-api-serial.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { RecentSearch, SearchType } from '../../models/mro/recent-search';
import { BulkSearchComponent } from 'src/app/pages/mro/search/bulk-search/bulk-search.component';
import { AdvancedSearch } from '../../models/mro/advanced-search';
import { MfgLoction } from '../../models/mro/mfg-loction';
import { Subscription, forkJoin } from 'rxjs';
import { Office } from '../../models/mro/office';
import * as moment from 'moment';
import { FlexSerialCard } from '../../models/mro/flex-serial-card';
import { AppType } from '../../enums/mro/app-type';
import { BulkSerialSearchResult } from '../../models/mro/bulk-serial';


@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent implements OnInit, OnDestroy {

  value: string | undefined;
  shipStartDate: Date | null | undefined;
  shipEndDate: Date | null | undefined;
  displayDefault: boolean = true;

  visible: boolean = false;
  advanceSearch = new AdvancedSearch();
  noteTypesInfo: string[] = [];
  mfgLoctionInfo: MfgLoction[] = [];
  selectedLocation: string | null | undefined = null;
  selectedOfficeId: null | string = null;
  selectedProduct: null | number = null;
  selectedNote: null | undefined = null;
  selectedNoteType: null | number | undefined = null;
  selectedCustomerName: null | undefined = null
  selectedSiteName: null | undefined = null;
  itemTypesArray = [ItemTypes.TagNo, ItemTypes.Project, ItemTypes.OracleOrderNo, ItemTypes.CustomerPO, ItemTypes.RepOrderNo, ItemTypes.CustomerNo];
  hideAdvanceSearchArray:string[] = [ItemTypes.SerialNo, ItemTypes.OracleOrderNo, ItemTypes.EMString, ItemTypes.Product] || [];

  placeholder: string = 'Search for Serials / Plant / Site / Order / PO / Tag / Project / Customers';
  searchIcon: string = 'assets/icons/search.svg';
  sizeIcon: string = 'assets/icons/size.jpg';
  sparkIcon: string = 'assets/icons/sparkIcon.svg';
  configureIcon: string = 'assets/icons/configure.svg';
  addIcon: string = 'assets/icons/add.svg';
  inventoryIcon: string = 'assets/icons/inventory.svg';
  closeIcon: string = 'assets/icons/x.svg';
  serialcardicon: string = 'assets/icons/serialcard.svg';
  isSearchVisible = false;

  faClock = faClock;
  faSearch = faMagnifyingGlass;
  faEdit = faPenToSquare;
  faFile = faFileLines;
  faSettings = faGear;
  activeTabIndex: number = 1; // Initially set to 0 for the first tab
  itemTypes = ItemTypes;
  searchOptions: SerialCardItems[] = [];
  searchDialogForm: FormGroup;
  @Output() closeSearch = new EventEmitter<void>();
  input_data: string = ''
  clearSearch: boolean = false;
  noConttentFound = signal<string>('');
  serialSearchPaylod = new SerialCard();
  radio_value: string = '1';
  save_data: string = '';
  bulk_search: boolean = false;
  searchFilterType = SearchFilterType;
  maxDate: Date = new Date();
  userOffices: Office[];
  subscription$: Subscription[] = [];
  serialSearchSubscription$: Subscription;
  loadDropdownData$: Subscription
  @ViewChild(BulkSearchComponent) bulkSearchComponent: BulkSearchComponent;

  str_data=new FormControl();
  recentSarchSubscribtion$: Subscription;
  recentSearchListSubscribtion$: Subscription;

  constructor(private renderer: Renderer2, private elementRef: ElementRef,
    public _searchService: MroApiSerialService, public router: Router,
    public culibrary: CommonUtilityLibraryService, private cd: ChangeDetectorRef,
    private fb: FormBuilder) {

  }

  showDialog() {
    this.visible = true;
  }
  customerNamesOptions = [
    // {
    //   type: 1,
    //   value: "Start With"
    // },
    // {
    //   type: 2,
    //   value: "Includes"
    // },
    {
      type: 3,
      value: "Exact"
    },
  ];
  noteNamesOptions = [
    {
      type: 1,
      value: "Start With"
    },
    {
      type: 2,
      value: "Includes"
    },
    {
      type: 3,
      value: "Exact"
    },
  ];
  siteNamesOptions = [
  // {
  //   type: 1,
  //   value: "Start With"
  // },
  // {
  //   type: 2,
  //   value: "Includes"
  // },
  {
    type: 3,
    value: "Exact"
  },
];
  productStructureOptions = [
    { name: 'EM_String', value: ProductStructureType.EM_String },
    { name: 'Product Number', value: ProductStructureType.Product },
  ]
  recentSearchResults: RecentSearch[] = [];
  
  deleteItem(searchNumber: SearchNumberVal[], index: number) {
    searchNumber!.splice(index, 1)
  }

  addItem(li: SearchNumberVal[]) {
    li.push({ searchNumberValue: '' });
  }

  addMultipleRow(item: SerialCardItems) {
    const valueTypeElements = item.typedValue.split(',');
    item.searchNumber = [];
    valueTypeElements.forEach((ele: string) => {
      this.searchOptions.forEach((option: SerialCardItems) => {
        if (option.value == item.value && valueTypeElements.length != option.searchNumber?.length) {
          option.searchNumber.push({ searchNumberValue: ele });
        }
      });
    });
    if (item.typedValue == '' && item.searchNumber?.length === 0) {
      item.searchNumber?.push({ searchNumberValue: '' });
    }
  }
  addParts(){
    this.displayDefault = false;
    this.router.navigate(['mro/add-parts']);
  }
  ngOnInit(): void {
    // this.displayDefault = true;
    this.router.events.subscribe(event => {
      // if (event instanceof NavigationEnd) {
      //   this.displayDefault =  event.url === '/mro' ? true : false;
      // }
      this.checkRoute();
    });
    this.checkRoute();
    this.searchOptionList();
    this.getRecentSearches();
    this.searchDialogForm = new FormGroup({
      searchText: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        // Validators.maxLength(21),
        Validators.pattern('^[a-zA-Z0-9-, ]*$')
      ]), // Search text
      searchField: new FormControl(this.itemTypes.SerialNo), // Item types
      searchType: new FormControl('1'),
      userEnteredNote: new FormControl(''),
      userEnteredProduct: new FormControl(''),
      userEnteredCustomerName: new FormControl(''),
      userEnteredSiteName: new FormControl(''),
      items: this.fb.array([]),
    });
    this.cd.detectChanges();
    this.loadDropdownData();
    this.selectedNoteType = 1;
    this.getLatestSearchHistory();
  }

  checkRoute() {
    const currentRoute = this.router.url;
    this.displayDefault = !currentRoute.includes('add-parts');
  }  
  searchOptionList() {
    return this.searchOptions = [
      { label: 'Serial #', typedValue: '', value: this.itemTypes.SerialNo, searchNumber: [], checked: true },
      { label: 'Rep Order #', typedValue: '', value: this.itemTypes.RepOrderNo, searchNumber: [], checked: false },
      { label: 'Oracle Order #', typedValue: '', value: this.itemTypes.OracleOrderNo, searchNumber: [], checked: false },
      { label: 'Customer PO', typedValue: '', value: this.itemTypes.CustomerPO, searchNumber: [], checked: false },
      { label: 'Tag #', typedValue: '', value: this.itemTypes.TagNo, searchNumber: [], checked: false },
      { label: 'Project #', typedValue: '', value: this.itemTypes.Project, searchNumber: [], checked: false },
      { label: 'Customer #', typedValue: '', value: this.itemTypes.CustomerNo, searchNumber: [], checked: false },
      { label: 'Customer Name', typedValue: '', value: this.itemTypes.CustomerName, searchNumber: [], checked: false },
      { label: 'Site #', typedValue: '', value: this.itemTypes.SiteNo, searchNumber: [], checked: false },
      { label: 'Site Name', typedValue: '', value: this.itemTypes.SiteName, searchNumber: [], checked: false },
      { label: 'EM String', typedValue: '', value: this.itemTypes.EMString, searchNumber: [], checked: false },
      { label: 'Product Number', typedValue: '', value: this.itemTypes.Product, searchNumber: [], checked: false },
    ];
  }
  showSearch(show: boolean): void {
    this.isSearchVisible = show;
    this.placeholder = this.isSearchVisible
      ? ' Enter one or more Serial #(s) Separated by commas'
      : ' Search for Serials / Plant / Site / Order / PO / Tag / Project / Customers';
    if (show) {
      this.renderer.addClass(document.querySelector('main'), 'overlay');
    } else {
      this.renderer.removeClass(document.querySelector('main'), 'overlay');
      this.onReset();
    }
  }
  // If click delete key in the keyboard
  clearDate(event: Event, dateType: 'start' | 'end'): void {
    const keyboardEvent = event as KeyboardEvent;
     if (dateType === 'start' && keyboardEvent.key === 'Delete') {
      this.shipStartDate = null;
    } else if (dateType === 'end' && keyboardEvent.key === 'Delete') {
      this.shipEndDate = null;
    }
    keyboardEvent.preventDefault();
  }

  handleDocumentClick(event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement;
    if (!clickedElement.classList.contains('search') && !clickedElement.parentElement?.classList.contains('search')) {
      this.showSearch(false);
      this.input_data = '';
      this.renderer.removeClass(document.querySelector('main'), 'overlay');
      this.bulkDisplay(false);
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    this.handleDocumentClick(event);
  }

  closeSearchtab(): void {
    this.isSearchVisible = false;
    this.bulkDisplay(false);
    this.renderer.removeClass(document.querySelector('main'), 'overlay');
  }

  // Define the setActiveTab method
  setActiveTab(index: number): void {
    this.activeTabIndex = index;
    if (this.activeTabIndex == this.searchFilterType.RecentSearch) {
      this.getRecentSearches();
    }
  }

  // Define the handleSearchClick method
  handleSearchClick(event: MouseEvent): void {
    event.stopPropagation();
  }
  onCloseNoResults() {
    this.noConttentFound.set('');
  }
  onHideAdditionalFilters(value: string) {
    return (value === this.itemTypes.SerialNo || value === this.itemTypes.OracleOrderNo || value === this.itemTypes.EMString || value === this.itemTypes.Product)
  }
  advanceSearchAssign() {
    // Filter out the selected search options that are not equal to the current radio value and have a typedValue
    const selectedSearchOption = this.searchOptions.filter(ele => ele.value != this.radio_value && ele.typedValue);
    // Transform the additional filter data from the selected search options
    const additionalFilter = this.transformAddtionalFilterData(selectedSearchOption);
    // Assign the transformed additional filter data to the respective fields in advanceSearch object
    this.advanceSearch.repOrderNumber = additionalFilter.repOrderNumber;
    this.advanceSearch.oracleOrderNumber = additionalFilter.OracleOrderNo;
    this.advanceSearch.tagNumber = additionalFilter.tagNumber;
    this.advanceSearch.customerNumber = additionalFilter.customerNumber;
    this.advanceSearch.customerPONumber = additionalFilter.customerPO;
    this.advanceSearch.projectNumber = additionalFilter.projectNumber;
    this.advanceSearch.siteNumber = additionalFilter.siteNumber;

    // Assign ship start and end dates, manufacturing location, and office number from the form to the advanceSearch object
    this.advanceSearch.shipStartDate = this.shipStartDate;
    this.advanceSearch.shipEndDate = this.shipEndDate;
    this.advanceSearch.manufacturingLocation = this.selectedLocation;
    this.advanceSearch.officeNumber = this.selectedOfficeId;
    // If customerName is part of the advanceSearch, assign type and value from the form
    if (this.advanceSearch.customerName) {
      this.advanceSearch.customerName.type = Number(this.selectedCustomerName);
      this.advanceSearch.customerName.value = this.searchDialogForm.value.userEnteredCustomerName;
    }
    // If notes are part of the advanceSearch, assign notes, type, and value from the form
    if (this.advanceSearch.notes) {
      this.advanceSearch.notes.notes = this.selectedNote;
      this.advanceSearch.notes.type = Number(this.selectedNoteType);
      this.advanceSearch.notes.value = this.searchDialogForm.value.userEnteredNote;
    }
    // If productStructure is part of the advanceSearch, assign value and type from the form
    if (this.advanceSearch.productStructure) {
      this.advanceSearch.productStructure.value = this.searchDialogForm.value.userEnteredProduct;
      this.advanceSearch.productStructure.type = Number(this.selectedProduct);
    } 
    // If Site Name is part of the advanceSearch, assign value and type from the form
    if(this.advanceSearch.siteName){
      this.advanceSearch.siteName.type = Number(this.selectedSiteName);
      this.advanceSearch.siteName.value = this.searchDialogForm.value.userEnteredSiteName;
    }
  }
  // Define the onApplySearch method api call
  onApplySearch() {
    this.searchDialogForm.value.searchType = this.searchDialogForm.value.searchField === ItemTypes.SerialNo ? ItemTypes.SerialNo : ItemTypes.TagNo;
    this.advanceSearchAssign();
    this.culibrary.show();
    this.serialSearchPaylod = {
      searchText: this.searchDialogForm.value.searchText.trim(),
      searchField: Number(this.searchDialogForm.value.searchField),
      searchType: Number(this.searchDialogForm.value.searchType),
      advancedSearch: this.advanceSearch,
    };
    const searchByLabelName = this.searchOptions.find(option => this.searchDialogForm.value.searchField == option.value)?.label.replace(/#/g, '') || '';

    this.serialSearchSubscription$ = this._searchService.getSearchedInformation(this.serialSearchPaylod).subscribe({
      next: (response: HeaderInfo) => {
        if (response?.errors && response.errors[0]?.code === '204' || response?.errors && response.errors[0]?.code === '400') {
          this.noConttentFound.set('No Content Found for the search criteria.');
          return;
        }
        if (response.serialCards?.length) {
          this._searchService.getBulkSerialResult({} as BulkSerialSearchResult);
          this.serialSearch(response, searchByLabelName);
        } else {
          this.deconstruction(response);
        }
        this._searchService.getSelectedTabValues(SerialTabEnum.ConstructionDetails);
        this.closeSearch.emit();
        this.showSearch(false);
        this.onReset();
        this.culibrary.hide();
        this.getRecentSearches();
      },
      error: (error) => {
        console.error('error', error);
        this.showSearch(true);
        this.noConttentFound.set('No Content Found for the search criteria.');
      },
    });
    this.subscription$.push(this.serialSearchSubscription$);
  }
  // This method transforms the input array of SerialCardItems into a structured object with arrays for each item type (TagNo, RepOrderNo, OracleOrderNo, CustomerPO, Project, CustomerNo), splitting typedValues by commas.
  transformAddtionalFilterData(inputData: SerialCardItems[]) {
    return inputData.reduce((acc: any, item: SerialCardItems) => {
      if (item.value == ItemTypes.TagNo && item.typedValue) {
        acc.tagNumber.push(...item.typedValue.split(','));
      } else if (item.value == ItemTypes.RepOrderNo && item.typedValue) {
        acc.repOrderNumber.push(...item.typedValue.split(','));
      } else if (item.value == ItemTypes.OracleOrderNo && item.typedValue) {
        acc.OracleOrderNo.push(...item.typedValue.split(','));
      } else if (item.value == ItemTypes.CustomerPO && item.typedValue) {
        acc.customerPO.push(...item.typedValue.split(','));
      } else if (item.value == ItemTypes.Project && item.typedValue) {
        acc.projectNumber.push(...item.typedValue.split(','));
      } else if (item.value == ItemTypes.CustomerNo && item.typedValue) {
        acc.customerNumber.push(...item.typedValue.split(','));
      } else if (item.value == ItemTypes.SiteNo && item.typedValue) {
        acc.siteNumber.push(...item.typedValue.split(','));
      }
      return acc;
    }, 
    { 
      tagNumber: [], 
      repOrderNumber: [], 
      customerPO: [], 
      projectNumber: [], 
      customerNumber: [], 
      OracleOrderNo: [], 
      siteNumber: [] 
    });
  }
  // Navigate to deconstruction page
  deconstruction(response: HeaderInfo) {
    this._searchService.getSerialSearchResponse(response);
    this._searchService.getSerialResults([]);
    this.router.navigate(['deconstruct']);
    this._searchService.updateTotalCount(0);
  };
// If it is only one array in serial card again need call serial search api
  serialSearch(response: HeaderInfo, searchByLabelName: string) {
    this._searchService.getSerialSearchResponse({});
    
    if (response.serialCards) {
      response.serialCards[0].searchType = searchByLabelName;
      response.serialCards[0].searchedValue = this.searchDialogForm.value.searchText;
      this._searchService.getSerialResults(response.serialCards || []);
      if (response.serialCards.length === 1) {
        this.searchDialogForm.value.searchText = response.serialCards[0].serialNumber;
        this.searchDialogForm.value.searchField = this.itemTypes.SerialNo;
        this.onApplySearch();
      }else{
        this.router.navigate(['search']);
      }
    }
  }
  //Additional filter dropdown values reset
  resetDropdown() {
    this.selectedLocation = null;
    this.selectedOfficeId = null;
    this.selectedProduct = null;
    this.selectedNote = null;
    this.selectedCustomerName = null;
    this.selectedNoteType = null;
    this.shipEndDate = null;
    this.shipStartDate = null;
  }
  // Define the onReset method
  onReset(): void {
    this.input_data = ''
    this.searchDialogForm.reset({
      searchText: '',
      searchField: this.itemTypes.SerialNo,
      searchType: '1',
      userEnteredNote: '',
      userEnteredProduct: '',
      userEnteredCustomerName: '',
      userEnteredSiteName: '',
    });
    this.radio_value = '1';
    this.resetDropdown();
    this.noConttentFound.set('');
    this.activeTabIndex = this.searchFilterType.RecentSearch;
    if (this.bulkSearchComponent) {
      this.bulkSearchComponent.onBulkReset(); // To clear the uploaded file on close icon click
    }
  }
  //Radio button change event
  switchSearchOption(val: string) {
    this.radio_value = val == this.itemTypes.CustomerName ? this.itemTypes.CustomerNo : val;
    if(this.radio_value === this.itemTypes.SerialNo || this.radio_value === this.itemTypes.OracleOrderNo || this.radio_value === this.itemTypes.EMString || this.radio_value === this.itemTypes.Product){
      this.activeTabIndex = this.searchFilterType.RecentSearch
    }
    else{
      this.activeTabIndex = this.searchFilterType.AdvancedSearch
    }
    const matchingItem = this.searchOptionList().find((item) => item.value === val);
    this.placeholder = matchingItem?`Enter one or more ${matchingItem.label}(s) Separated by comma`:'';
    this.searchOptionList();
    this.resetDropdown();
  
  }

  marginStyle = {
    padding: '15px !important',
    left: '0px',
    top: '0px'
  }

  closeOverlay(op: OverlayPanel) {
    op.hide()
  }

  applyMultiple(op: OverlayPanel, item: SerialCardItems) {
    if (item.searchNumber) {
      item.typedValue = item.searchNumber.map((ele: SearchNumberVal) => ele.searchNumberValue).join(',');
    }
    op.hide()
  }

  bulkDisplay(flag: boolean) {
    this.bulk_search = flag;
    this.onReset();
  }

  recentSelectedItem(event: { id: number; type: string }) {
    const selectedSearch: SearchType | undefined = this.recentSearchResults.find((item, index) => index === event.id)?.searchQuery;
    if (selectedSearch) {
      this.searchDialogForm.patchValue({
        searchText: selectedSearch.searchText,
        searchField: selectedSearch.searchField.toString(),
      });
      this.getInput(selectedSearch.searchText); // to update input_data
      this.advanceSearch.repOrderNumber = selectedSearch.advancedSearch.repOrderNumber;
    }
    if (event.type == 'applySearch') {
      this.onApplySearch();
    } else {
      this.hideAdvanceSearchArray.includes(selectedSearch?.searchField.toString() ?? '') ? this.setActiveTab(this.searchFilterType.RecentSearch) : this.setActiveTab(this.searchFilterType.AdvancedSearch);
      this.searchOptions.forEach((option) => Number(option.value) === selectedSearch?.searchField ? option.checked = true : option.checked = false);
      this.updateSearchOptionsAdvanceSearch(selectedSearch);
      this.radio_value = selectedSearch?.searchField.toString() || '1';
      this.showSearch(true);
    }
  }
  updateSearchOptionsAdvanceSearch(selectedSearch: SearchType | undefined) {

    if (selectedSearch) {
      this.searchOptions.forEach((option) => {
        if (selectedSearch.advancedSearch) {
          if (option.value == ItemTypes.TagNo && selectedSearch.advancedSearch.tagNumber) {
            option.typedValue = selectedSearch.advancedSearch.tagNumber.join(',');
          } else if (option.value == ItemTypes.RepOrderNo && selectedSearch.advancedSearch.repOrderNumber) {
            option.typedValue = selectedSearch.advancedSearch.repOrderNumber.join(',');
          } else if (option.value == ItemTypes.CustomerPO && selectedSearch.advancedSearch.customerPONumber) {
            option.typedValue = selectedSearch.advancedSearch.customerPONumber.join(',');
          } else if (option.value == ItemTypes.Project && selectedSearch.advancedSearch.projectNumber) {
            option.typedValue = selectedSearch.advancedSearch.projectNumber.join(',');
          } else if (option.value == ItemTypes.CustomerNo && selectedSearch.advancedSearch.customerNumber != null) {
            option.typedValue = selectedSearch.advancedSearch.customerNumber.join(',');
          }
        }
      });
      if (selectedSearch.advancedSearch.shipStartDate) {
        this.shipStartDate = new Date(moment(selectedSearch.advancedSearch.shipStartDate).format('DD-MMM-YY'));
        this.shipEndDate = new Date(moment(selectedSearch.advancedSearch.shipEndDate).format('DD-MMM-YY'));
      }

      this.selectedLocation = selectedSearch.advancedSearch.manufacturingLocation;
      this.selectedOfficeId = selectedSearch.advancedSearch.officeNumber;
      if (selectedSearch.advancedSearch.notes) {
        this.selectedNote = selectedSearch.advancedSearch.notes.notes;
        this.selectedNoteType = selectedSearch.advancedSearch.notes.type;
        this.searchDialogForm.patchValue({
          userEnteredNote: selectedSearch.advancedSearch.notes.value,
        });
      }
      if (selectedSearch.advancedSearch.productStructure) {
        this.selectedProduct = selectedSearch.advancedSearch.productStructure.type;
        this.searchDialogForm.patchValue({
          userEnteredProduct: selectedSearch.advancedSearch.productStructure.value,
        });
      }
    }
  }

  getRecentSearches() {
    this.recentSarchSubscribtion$ = this._searchService.getRecentSearches().subscribe({
      next: (response: RecentSearch[]) => {
        this.recentSearchResults = response.slice(0, 5);
      },
      error: (error) => {
        console.error('error', error);
      },
    });
    this.subscription$.push(this.recentSarchSubscribtion$);
  }
  getLatestSearchHistory() {
    this.recentSearchListSubscribtion$ = this._searchService.recentSearchList$.subscribe({
      next: (response: RecentSearch[]) => {
        this.recentSearchResults = response.slice(0, 5);
      },
      error: (error) => {
        console.error('error', error);
      },
    });
    this.subscription$.push(this.recentSearchListSubscribtion$);
  }
  displaySearch(flag: boolean) {
    this.showSearch(flag);
    this.bulkDisplay(flag);
  }
  // Additional filter dropdown data getting it from api
  loadDropdownData() {
    this.loadDropdownData$ = forkJoin({
      mfgLocations: this._searchService.getMFGLocations(),
      offices: this._searchService.getOffices(),
      noteTypes: this._searchService.getNoteTypes()
    }).subscribe({
      next: ({ mfgLocations, offices, noteTypes }) => {
        this.mfgLoctionInfo = mfgLocations;
        this.userOffices = offices.map((office:Office) => ({
          officeId: office.officeId,
          officeName: office.officeName,
          officeCity: office.officeCity,
          concatenatedValue: `${office.officeId} - ${office.officeName} - ${office.officeCity}`,
        }));
        this.noteTypesInfo = noteTypes;
      },
      error: (error) => {
        console.error(error);
      }
    });
    this.subscription$.push(this.loadDropdownData$);
  }
  downloadSerialCardPdf(){
    const flexSerialCard = new FlexSerialCard();
    flexSerialCard.serialNumber = this.searchDialogForm.value.searchText;
    this._searchService.getFlexSerialCard(flexSerialCard).subscribe(response=>{
      if(response.status === 200){
        this._searchService.downloadSerialCardPdf(response.body as Blob, this.searchDialogForm.value.searchText, AppType.PDF);
      }else{
        this.noConttentFound.set('No Content Found for the search criteria.');
      }
    });
  }
  ngOnDestroy(): void {
    this.displayDefault = true;
    this.subscription$.forEach(subscription => subscription.unsubscribe());
  }

  getInput(data:string) {
    // this.searchDialogForm.value.searchText
    this.input_data=data.trim();
    this.str_data.setValidators(Validators.required)
    this.str_data.setValidators(Validators.minLength(1))
    this.str_data.setValidators(Validators.pattern('^[a-zA-Z0-9- ]*$'))
    this.searchDialogForm.get('searchText')?.setValue(data)
  }
}
