import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, map } from 'rxjs';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { Config } from '../../config';
import { ConstructionPayload } from '../../models/mro/construction-payload';
import { HeaderInfo } from '../../models/mro/header-info';
import { SerialCardRequest } from '../../models/mro/serial-card-request';
import { SerialCardDeconstruction } from '../../models/mro/serial-card-deconstruction';
import { CommonUtilityLibraryService } from '../../library/common-utility-library.service';
import { SearchResultSerialCard } from '../../models/mro/serial-cards-search';
import { BOMPayload } from '../../models/mro/bom-payload';
import { BOM } from '../../models/mro/bom';
import { ApiResponse } from '../../models/mro/common-api-response';
import { ProductsAttributePayload } from '../../models/mro/products-attribute-payload';
import { Construction as ProductsInfo } from  'src/app/pages/tag-workspace/store/models/build-assembly/buildassembly';
import { RecentSearch } from '../../models/mro/recent-search';
import { BulkSerialSearchResult } from '../../models/mro/bulk-serial';
import { MfgLoction } from '../../models/mro/mfg-loction';
import { Office } from '../../models/mro/office';
import { Document, DocumentResponse } from '../../models/mro/document';
import { FlexSerialCard } from '../../models/mro/flex-serial-card';
import { ProductCompareResponse } from '../../models/mro/products-compare';
import { ProductsCompareRequest } from '../../models/mro/products-compare-request';
import { AppType } from '../../enums/mro/app-type';
import { FieldChangeRequest, FieldChange } from '../../models/mro/field-changes';
import { AddPartsInfo } from '../../models/mro/parts';
import { errorMsg } from '../../models/mro/error-msg';
import { PackingListPayload } from '../../models/mro/packinglist-payload';
import { compareAPIPayload, CompareSerialPayload } from '../../models/mro/compare-serial-payload';
import { ProductsEmstringResponse } from '../../models/mro/products-emstring-response';

@Injectable({
  providedIn: 'root'
})
export class MroApiSerialService {

  private config: Config;

  // BehaviorSubject to hold the response of the serial search
  public serialSearchResponse = new BehaviorSubject<HeaderInfo>({} as HeaderInfo);

  // Observable to expose the serialSearchResponse BehaviorSubject
  public serialSearchResponse$ = this.serialSearchResponse.asObservable();

  public refreshSelectedTab = new BehaviorSubject(1);
  public refreshSelectedTab$ = this.refreshSelectedTab.asObservable();

  browserBackSubcription$: Subscription;

  public serialCardResults = new BehaviorSubject<SearchResultSerialCard[]>([]);
  public serialCardResults$ = this.serialCardResults.asObservable();

  public bulkSerialSearchResult = new BehaviorSubject<BulkSerialSearchResult>(new BulkSerialSearchResult());
  public bulkSerialSearchResult$ = this.bulkSerialSearchResult.asObservable();

  public recentSearchList = new BehaviorSubject<RecentSearch[]>([]);
  public recentSearchList$ = this.recentSearchList.asObservable();

  private totalCount = new Subject<number>();
  totalCount$ = this.totalCount.asObservable();

  constructor(
    private apiService: CommonApiService,
    private culibrary: CommonUtilityLibraryService,
  ) {
    this.config = new Config();
  }

  updateTotalCount(count: number) {
    this.totalCount.next(count);
  }

  // This function sends a POST request to the GET_SERIAL_CARD_API endpoint with the provided request object
  public getSearchedInformation(request: SerialCardRequest): Observable<HeaderInfo> {
    return this.apiService.post(this.config.GET_SERIAL_CARD_API, request);
  }

  // This function sends a POST request to the GET_CONSTRUCTION_DETAILS_API endpoint with the provided request object
  public getConstructionDetails(request: ConstructionPayload): Observable<SerialCardDeconstruction> {
    return this.apiService.post(this.config.GET_CONSTRUCTION_DETAILS_API, request);
  }

  // public getBuildAssembly(){
  //   return this.apiService.get("assets/assembly/build-assembly.json");
  // }
  public getProductsAttributesInfo(request: ProductsAttributePayload): Observable<ProductsInfo> {
    return this.apiService.post(this.config.GET_PRODUCT_ATTRIBUTE_INFO, request);
  }
  public getSerialSearchResponse(data: HeaderInfo) {
    this.serialSearchResponse.next(data as HeaderInfo);
  }

  public getSelectedTabValues(tabId: number) {
    this.refreshSelectedTab.next(tabId)
  }

  public getSerialResults(serialCards: SearchResultSerialCard[]) {
    return this.serialCardResults.next(serialCards)
  }

  public getBulkSerialResult(bulkSerialSearch: BulkSerialSearchResult) {
    return this.bulkSerialSearchResult.next(bulkSerialSearch)
  }

  public downloadExcelFile(data: BlobPart, filename: string) {
    const blob: Blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url: string = window.URL.createObjectURL(blob);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.xlsx`); // or any other filename you want
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  disableBrowserNavigation(): void {
    this.browserBackSubcription$ = this.culibrary.disableBrowserBack().subscribe((_) => {
      history.pushState(null, '');
      this.culibrary.openBrowserNavigationPopup();
    });
  }

  setDestinationPage(urlPath: string) {
    this.culibrary.setDestinationPage(urlPath);
  }

  cleanUp(): void {
    this.browserBackSubcription$.unsubscribe();
  }

  getBOMDetails(request: BOMPayload): Observable<ApiResponse<BOM[]>> {
    return this.apiService.post(this.config.GET_BOM_INFO, request);
  }

  getRecentSearches(): Observable<RecentSearch[]> {
    return this.apiService.get(this.config.GET_RECENT_SEARCH_INFO);
  }
  getRecentSearchList(recentSearcheListValue:RecentSearch[]){
    this.recentSearchList.next(recentSearcheListValue);
  }
  public getBulkSearchDownlaodTemplate(value:string): Observable<Blob> {
    const request = `${this.config.GET_BULK_SEARCH_DOWNLOAD_TEMPLATE}?bulkUploadType=${value}`;
    return this.apiService.getBlob(request);
  }

  public postBulkSearchUpload(searchField: string, file: File): Observable<BulkSerialSearchResult> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('searchField', searchField);
    return this.apiService.post(`${this.config.POST_BULK_SEARCH_UPLOAD}`, formData);
  }

  public exportAttributes(rquestPayload: ProductsAttributePayload): Observable<Blob> {
    return this.apiService.post(this.config.GET_EXPORT_ATTRIBUTES, rquestPayload, { responseType: 'blob' });
  }

  public getMFGLocations(): Observable<MfgLoction[]> {
    return this.apiService.get(this.config.GET_MFG_LOCATIONS);
  }

  getOffices(): Observable<Office[]> {
    return this.apiService.getStaticData(this.config.GET_OFFICE_LIST_API);
  }

  public getNoteTypes(): Observable<string[]> {
    return this.apiService.get(this.config.GET_NOTE_TYPES);
  }
  public getDocuments(documentList: ConstructionPayload): Observable<Document[]> {
    return this.apiService.post<DocumentResponse[]>(this.config.GET_DOCUMENTLIST, documentList).pipe(
      map((response) => response[0].DOCUMENTATION)
    );
  }

  public getDocumentFile(document: Document): Observable<Blob> {
    return this.apiService.post<File>(this.config.GET_DOCUMENT_FILE, document, { responseType: 'blob' });
  }

  public getFlexSerialCard(flexSeriaCard: FlexSerialCard): Observable<HttpResponse<Blob>> {
    return this.apiService.post(this.config.GET_FLEX_SERIAL_CARD, flexSeriaCard, { responseType: 'blob', observe: 'response', });
  }
  public getFlexSerialCardReport(request:any): Observable<HttpResponse<Blob>> {
    return this.apiService.post(this.config.GET_FLEX_SERIAL_CARD_REPORT,request,{ responseType: 'blob', observe: 'response', });
  }

  public downloadSerialCardPdf(data: BlobPart, filename: string, type: string) {
    const blob: Blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob); // Create a URL for the blob
    const anchor = document.createElement('a'); // Create an <a> element
    anchor.href = url;
    let extension = '';
    if (type === AppType.PDF) {
    extension = 'pdf';
    } else if (type === AppType.ZIP) {
    extension = 'zip';
    }
    anchor.download = `${filename}.${extension}`; // Set the filename
    anchor.click(); // Trigger the download
    window.URL.revokeObjectURL(url); // Clean up the URL object
  }
  downloadSerialCard(serialNumber: string[], docType:number, serialReportCardType: number): Observable<any> {
    const serialCard = new FlexSerialCard();
    serialCard.serialNumber = serialNumber;
    serialCard.documnetType = docType;
    serialCard.serialReportCardType = serialReportCardType;
    return this.getFlexSerialCardReport(serialCard);
  }
  public getSPIRExcelReport(serialNumbers: { serialNumber: string[] }): Observable<BlobPart> {
    return this.apiService.post(this.config.GET_SPIREXCELREPORT, serialNumbers, { responseType: 'blob' });
  }
  public getProductsCompareInfo(productNumbers: ProductsCompareRequest): Observable<ProductCompareResponse> {
    return this.apiService.post(this.config.GET_PRODUCT_COMPARE,  productNumbers );
  }
  public getPNCompareExcelExport(productNumbers: ProductsCompareRequest): Observable<BlobPart> {
    return this.apiService.post(this.config.GET_PN_COMPARE_EXCEL_EXPORT, productNumbers, { responseType: 'blob' });
  }
  public getFieldChanges(fieldChangeRequest: FieldChangeRequest): Observable<FieldChange[]> {
    return this.apiService.post(this.config.GET_FIELD_CHANGE,fieldChangeRequest)
  }

  public getPartsDetails(request: { partNumbers: string}): Observable<AddPartsInfo> {
    return this.apiService.post(this.config.GET_PARTS_DETAILS, request);
  }

  public getExcelPartsData(file: File): Observable<AddPartsInfo> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.apiService.post(`${this.config.GET_BULK_PARTS_DATA}`, formData);
  }

  public getSearchAdditionalFields(request:FieldChangeRequest): Observable<errorMsg> {
    return this.apiService.post(this.config.GET_SEARCH_ADDITIONAL_FIELDS,request);
  }

  public getPackingList(request: PackingListPayload): Observable<Blob> {
    return this.apiService.post<File>(this.config.GET_PACKING_LIST, request, { responseType: 'blob' });
  }

  public getProductsEmStringData(request: CompareSerialPayload): Observable<ProductsEmstringResponse> {
    return this.apiService.post(this.config.GET_PRODUCTS_EMSTRING_DATA,request);
  }

  private compare = new BehaviorSubject<number>(1);
  sendCompare(val:number) {
    this.compare.next(val)
  }
  getCompare(): Observable<number> {
    return this.compare.asObservable();
  }

  public getComparedInfo(request: compareAPIPayload): Observable<any> {
    return this.apiService.post(this.config.GET_COMPARED_INFO, request);
  }
}
